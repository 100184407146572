<template>
  <el-card class="box-card">
    <el-col :span="8">
      <el-button type="warning" @click="exportUser(0)" size="small">导出用户</el-button>
      <el-button type="primary" @click="exportUser(1)" size="small">导出消费用户</el-button>
    </el-col>
    <el-col :span="16" style="text-align: right">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="名字:">
          <el-input
              v-model="queryForm.name"
              clearable
              placeholder="请输入用户名字"
              size="small"
          />
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input
              v-model="queryForm.phone"
              clearable
              placeholder="请输入手机号"
              size="small"
          />
        </el-form-item>
        <el-form-item label="性别:">
          <el-select v-model="queryForm.sex" clearable placeholder="请选择" size="small">
            <el-option label="保密" value="0"/>
            <el-option label="男" value="1"/>
            <el-option label="女" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>

    <el-table
        :data="list"
        @selection-change="setSelectRows"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection"/>
      <el-table-column
          align="center"
          label="ID"
          prop="id"
          show-overflow-tooltip
          width="70"
      />
      <el-table-column
          align="center"
          label="用户名"
          prop="name"
          show-overflow-tooltip
      />
      <el-table-column align="center" label="头像">
        <template #default="{ row }">
          <el-image v-if="row.pic" :src="row.pic" class="commodity_pic"/>
          <el-image v-else src="#" class="commodity_pic"/>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="手机号码"
          prop="phone"
          show-overflow-tooltip
      >
        <template #default="{row}">
          <span v-if="row.phone">{{ row.phone }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          :formatter="formatterSex"
          label="性别"
          prop="sex"
          show-overflow-tooltip
          width="80"
      />
      <el-table-column
          align="center"
          label="用户等级"
          prop="user_level.name"
          show-overflow-tooltip
      />
      <el-table-column
          align="center"
          label="积分余额"
          prop="user_wallet.credit"
          show-overflow-tooltip
          width="80"
      >
        <template #default="{row}">
          <span v-if="row.user_wallet">{{ row.user_wallet.credit }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="消费金额"
          prop="consumeMoney"
          show-overflow-tooltip
          width="80"
      />
      <el-table-column
          align="center"
          label="消费次数"
          prop="consumeCount"
          show-overflow-tooltip
          width="80"
      />
      <el-table-column
          align="center"
          label="注册时间"
          prop="create_at"
          show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="操作"
          show-overflow-tooltip
          width="50"
      >
        <template #default="{ row }">
          <el-button type="text" @click="userDetails(row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import {apiGetUserList, apiGetUsersExcel} from '@/request/api'

export default {
  name: 'UserList',
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  data() {
    return {
      activeName: '1',
      imgShow: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        role: 'user',
        name: '',
        phone: '',
        sex: '',
      },
      list: [],
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    userDetails(row) {
      if (row.id) {
        this.$router.push({
          path: 'userDetails',
          query: {
            id: row.id,
          },
        })
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    fetchData() {
      this.listLoading = true
      apiGetUserList(this.queryForm).then(res => {
        if (res.code == 200) {
          console.log(res.data)
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
    // 性别
    formatterSex: function (row) {
      return row.sex == '0'
          ? '保密'
          : row.sex == '1'
              ? '男'
              : row.sex == '2'
                  ? '女'
                  : '暂无'
    },
    // 导出
    exportUser(num) {
      apiGetUsersExcel({
        is_consume: num,
        name: this.queryForm.name,
        phone: this.queryForm.phone,
        sex: this.queryForm.sex
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          window.location.href = res.data[0]
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}

.button {
  margin-top: 20px;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
